<template>
  <div>
    <v-container>
      <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card>
                    <v-row>
                      <v-col cols="5" class="mt-n5 mb-n8">
                        <v-card-title class="h6">Datos de liquidación</v-card-title>
                      </v-col>
                    </v-row>
                    <v-card-text>
                      <v-row class="mb-n2">
                        <!-- Numero de expediente -->
                        <v-col cols="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Nº de expediente"
                            v-model="numeroExpediente"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Estado de liquidacion -->
                        <v-col cols="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Estado de la liquidación"
                            v-model="estadoLiquidacion"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Total del Comprobante -->
                        <v-col cols="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Total del Comprobante"
                            v-model="totalComprobante"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Cancelado Débitos o NC -->
                        <v-col cols="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Cancelado Débitos o NC"
                            v-model="cancelado"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mb-n5">
                        <!-- Importe pagado -->
                        <v-col cols="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Importe pagado"
                            v-model="importePagado"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Saldo -->
                        <v-col cols="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Saldo"
                            v-model="saldo"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Total OP Entregadas -->
                        <v-col cols="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Total OP Entregadas"
                            v-model="opEntregadas"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Total OP con Recibo -->
                        <v-col cols="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Total OP con Recibo"
                            v-model="opRecibo"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card>
                    <v-card-title class="h6 mt-n1 mb-n5">Datos SSS</v-card-title>
                    <!-- tabla de datos de prestacion a sss -->
                    <v-data-table
                      :headers="headersDatosSSS"
                      :items="itemsDatosSSS"
                      height="100%"
                      fixed-header
                      item-key=""
                      class="elevation-1"
                      :loading="isLoadingDatosSSS"
                      hide-default-footer
                    >
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card>
                    <v-card-title class="h6 mt-n1 mb-n5">Afiliados</v-card-title>
                    <!-- tabla de afiliados con ordenes dentro del expediente -->
                    <v-data-table
                      :headers="headersAfiliados"
                      :items="itemsAfiliados"
                      height="100%"
                      fixed-header
                      item-key=""
                      class="elevation-1"
                      :loading="isLoadingAfiliados"
                      :search="search"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-row>
                            <v-col cols="6">
                              <v-text-field
                                v-model="search"
                                :append-icon="searchIcon"
                                label="Buscar"
                                single-line
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-toolbar>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
 
<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
export default {
  name: "EstadoFacturaPrestador",
  components: {
    GoBackBtn,
  },
  created() {
    this.setDatosEstado();
  },
  data() {
    return {
      routeToGo: "DetalleComprobanteProv",
      searchIcon: enums.icons.SEARCH,
      search: "",
      numeroExpediente: null,
      estadoLiquidacion: null,
      totalComprobante: null,
      cancelado: null,
      importePagado: null,
      saldo: null,
      opEntregadas: null,
      opRecibo: null,
      isLoadingDatosSSS: false,
      isLoadingAfiliados: false,
      itemsDatosSSS: [],
      headersDatosSSS: [
        {
          text: "Nº Liquidación SSS",
          value: "numeroLiquiSss",
          sortable: false,
          align: "end",
        },
        {
          text: "Código de práctica",
          value: "codPractica",
          sortable: false,
          align: "end",
        },
        {
          text: "Importe solicitado",
          value: "importeSolicitado",
          sortable: false,
          align: "end",
        },
        {
          text: "Importe liquidado",
          value: "importeLiquidado",
          sortable: false,
          align: "end",
        },
        {
          text: "Saldo SSS",
          value: "saldoSss",
          sortable: false,
          align: "end",
        },
        {
          text: "Nº Expediente SSS",
          value: "numeroExpedienteSss",
          sortable: false,
          align: "end",
        },
      ],
      itemsAfiliados: [],
      headersAfiliados: [
        {
          text: "Nombre",
          value: "nombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Apellido",
          value: "apellido",
          sortable: false,
          align: "start",
        },
        {
          text: "Documento",
          value: "documento",
          sortable: false,
          align: "start",
        },
        {
          text: "CUIL",
          value: "cuil",
          sortable: false,
          align: "start",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getDatosLiquidaYSssComp: "proveedores/getDatosLiquidaYSssComp",
    }),
    async setDatosEstado() {
        const response = await this.getDatosLiquidaYSssComp(this.$route.params.movProvId);
        this.itemsDatosSSS = response.datosSss;
        this.itemsAfiliados = response.datosAfiliados;
        this.numeroExpediente = response.datosLiquidacion.numeroExpediente;
        this.estadoLiquidacion = response.datosLiquidacion.estadoLiqui;
        this.totalComprobante = response.datosLiquidacion.total;
        this.cancelado = response.datosLiquidacion.cancelado;
        this.importePagado = response.datosLiquidacion.importePagado;
        this.saldo = response.datosLiquidacion.saldo;
        this.opEntregadas = response.datosLiquidacion.totalOpEntregadas;
        this.opRecibo = response.datosLiquidacion.totalOpConRecibo;
    },
  },
};
</script>
 
<style scoped>
</style>